import '../css/App.css';
import Intro from"./Intro"
import Footer from "./Footer"

export default function App() {
  return (
    <div className="App">
     <Intro/>
     <Footer/>

    </div>
  );
}

